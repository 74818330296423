$primary: #0064a4;
$secondary: #ffd200;
$accent-1: #6aa2b8;
$accent-2: #c6beb5;
$accent-3: #1b3d6d;
$accent-4: #f78d2d;

$background: #EBEBEB;

$white: #fff;
$black: #1a1a1a;
$error: #da0222;
$success: #7ab800;

// Grayscale
$gray-98: #fafafa;
$gray-97: #f7f7f7;
$gray-93: #ededed;
$gray-89: #e2e2e2;
$gray-87: #dedede;
$gray-78: #c7c6c6;
$gray-76: #c1c1c2;
$gray-58: #949494;
$gray-56: #8e8e8e;
$gray-55: #737373;
$gray-32: #525252;
$gray-20: #333333;
